$white: #ffffff;
$brown: #917236;
$red: #C61623;
$gold: #EABB66;
$black: #000000;


.CompEndModal {
    background: transparent;
    outline: none;
    border: none;
    box-shadow: none;
    padding: 0;
    box-sizing: border-box;
    margin: auto;
    * {
        box-sizing: border-box;
    }
    &::backdrop {
        background: $black;
        opacity: 0.75;
    }
    &::-webkit-scrollbar {
        display: none;
    }
    @media screen and (max-width:480px){
        padding: 10px !important;
    }
    :focus-visible {
        outline: none !important;
    }
    &:focus-visible {
        outline: none;
    }
    .closeIcon {
        cursor: pointer;
        position: absolute;
        right: 1rem;
        top: 1rem;
        path {
            transition: 300ms ease-in-out;
        }
        &:hover path {
            opacity: 0.5;
        }
    }
    .modal__box {
        position: relative;
        border-radius: 15px;
        background-color: $white;
        outline: none;
        border: none;
        box-shadow: none;
        width: 100%;
        width: 800px;
        @media screen and (max-width:1000px){
            width: 600px;
        }
        @media screen and (max-width:820px){
            width: auto;
        }
    }
    .modal__content {
        padding: 50px 70px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        @media screen and (max-width:480px){
            padding: 2rem 1rem !important;
        }
    }
    h2 {
        font-family: "Optima CE", Arial;
        font-size: 48px;
        font-weight: 400;
        line-height: 48px;
        text-align: center;
        color: $brown;
        text-transform: none;
        &:after {
            content: none;
        }
        @media screen and (max-width:480px){
            font-size: 30px;
            br {
                display: none;
            }
        }
    }
    .modal__button {
        font-family: "Optima CE", Arial;
        border: 1px solid $brown;
        background: $brown;
        padding: 10px 40px;
        color: $white;
        text-decoration: none;
        font-size: 18px;
        font-weight: 700;
        line-height: 18px;
        text-align: center;
        margin-bottom: 3rem;
        transition: 300ms ease-in-out;
        text-transform: uppercase;
        cursor: pointer;
        &:hover {
            background: transparent;
            color: $brown;
        }
    }
    p {
        font-size: 18px;
        font-weight: 400;
        line-height: 24px;
        text-align: center;
        strong {
            font-weight: 700;
        }
    }
    .modal__socials {
        padding: 1rem 1rem 0;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1.5rem;
    }
    .socialIcon {
        path {
            transition: 300ms ease-in-out;
        }
        &:hover path {
            fill: $brown;
        }
    }
    .modal__button--nextComp {
        background: transparent;
        border: 1px solid $brown;
        color: $brown;
        margin-bottom: 1rem;
        transition: 300ms ease-in-out;
        text-transform: uppercase;
        &:hover {
            background: $brown;
            color: $white;
        }
    }
    .modal__candy, .modal__choco {
        display: none;
        @media screen and (max-width:480px){
            display: none !important;
        }
    }
}

.CompEndModal--lindor {
    color: $white;
    padding: 3rem 5rem;
    .modal__box {
        background: $red;
    }
    h2 {
        color: $white;
    }

    .modal__button {
        border: 1px solid $gold;
        background: $gold;
        &:hover {
            background: transparent;
        }
    }

    .modal__button--nextComp {
        background: transparent;
        border: 1px solid $gold;
        color: $white;
        &:hover {
            background: $gold;
        }
    }
    .socialIcon {
        path {
            fill: $white;
        }
        &:hover path {
            fill: $gold;
        }
    }
    .modal__candy {
        display: block;
        position: absolute;
        z-index: 100;
    }
    .modal__candy--1 {
        width: 200px;
        top: -20px;
        left: 0px;
    }
    .modal__candy--2 {
        width: 200px;
        bottom: 0px;
        right: 0px;
    }
}

.CompEndModal--excellence {
    padding: 1rem 0;
    h2 {
        color: $black;
    }
    .modal__content {
        padding: 50px 100px;
    }
    .modal__button {
        border: 1px solid $black;
        background: $black;
        &:hover {
            background: transparent;
            color: $black;
        }
    }

    .modal__button--nextComp {
        background: transparent;
        border: 1px solid $black;
        color: $black;
        &:hover {
            background: $black;
            color: $white;
        }
    }
    .modal__choco {
        display: block;
        position: absolute;
        z-index: 100;
    }
    .modal__choco--1 {
        width: 150px;
        top: -10px;
        left: 0;
    }
    .modal__choco--2 {
        width: 100px;
        bottom: 0px;
        right: 0;
    }
    .socialIcon {
        path {
            fill: $black;
        }
        &:hover path {
            fill: $brown;
        }
    }
}